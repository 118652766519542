$(document).ready(function(){

    $("#nav-toggle").click(function(){
        $("#mobile-menu, #nav-toggle").toggleClass("active"); 
    });

    $(".nav-links .dropdown").click(function(){
        $(".dropdown").removeClass("active");
        $(this).toggleClass("active"); 
    });

    $('.dropdown').on('click', function(e) {
        e.stopPropagation();
    });

    $(document).on('click', function (e) {
        $(".dropdown").removeClass("active");
    });


});
